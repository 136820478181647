import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { URL_WEBSITE } from '../../config/config';
import { SettingsService } from '../../services/settings.service';
import { ImagesLazyloadModule } from '../../shared/images-lazyload/images-lazyload.module';

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [CommonModule,FormsModule, RouterModule,ImagesLazyloadModule],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.css'
})
export class CartComponent {
  cartItems: any[] = [];
  settings:any=[];
  title:string="Giỏ hàng";

  constructor(private cartService: CartService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    this.loadCartItems();
    this.settingsService.settings$.subscribe(
      (data) => {
        this.settings = data;
        this.updateMetaTags();
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
  }

  loadCartItems(): void {
    this.cartItems = this.cartService.getCartItems();
  }

  removeFromCart(productId: number, product_variation_id: number): void {
    const confirmed = confirm('Bạn có chắc chắn muốn xóa sản phẩm này khỏi giỏ hàng?');
    if (confirmed) {
      this.cartService.removeFromCart(productId, product_variation_id);
      this.loadCartItems(); // Reload items after removal
    }
    
  }

  updateQuantity(item:any) {
    this.cartService.updateQuantity(item);
  }

  getTotalPrice(): number {
    return this.cartItems.reduce((total, item) => total + item.price*item.quantity, 0);
  }

  private updateMetaTags(): void {
    if (isPlatformBrowser(this.platformId)) {
      const title = this.title || this.settings.title;
      const description = this.settings.description;
      const imageUrl = this.settings.photo;

      this.titleService.setTitle(title+ " - "+URL_WEBSITE);
      this.metaService.updateTag({ name: 'description', content: description });
      this.metaService.updateTag({ property: 'og:title', content: title });
      this.metaService.updateTag({ property: 'og:description', content: description });
      this.metaService.updateTag({ property: 'og:url', content: window.location.href });
      this.metaService.updateTag({ property: 'og:image', content: imageUrl });
      this.metaService.updateTag({ name: 'keywords', content:this.settings.keywords });
    }
  }
}
