import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '../../home/service/home.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { SettingsService } from '../../../services/settings.service';
import { URL_WEBSITE } from '../../../config/config';

@Component({
  selector: 'app-detail-article',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './detail-article.component.html',
  styleUrl: './detail-article.component.css'
})
export class DetailArticleComponent {
  title:string='Giới thiệu';
  settings:any=[];
  blog:any=[];
  type:string='';
  com:string='';
  news_id:string='';
  totalPages:number = 0;
  currentPage:number = 1;
  constructor(
    private homeService: HomeService,
    public activedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private settingsService: SettingsService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ){}
  ngOnInit(): void {
    this.activedRoute.params.subscribe((resp:any)=>{
      const slug=resp['slug'];
      const parts = slug.match(/(.*?)-p(\d+)\.html$/);
      //this.news_lug = parts[1]; // Phần đầu là slug
      this.news_id = parts[2];console.log(this.news_id);
      //this.news_id = resp.slug;
      this.detailBlogs();
    })
    this.activedRoute.data.subscribe(data => {
      this.type = data['type'];
      this.title = data['title'];
      this.com = data['com'];
    });
  }

  detailBlogs(){
    this.homeService.getAboutDetail(this.news_id).subscribe((resp:any)=>{
      this.blog=resp.blog;
    })
  }
}
