import { ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../../services/settings.service';
import { BrandService } from '../../brand/service/brand.service';
import { ProductService } from '../service/product.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ImagesLazyloadModule } from '../../../shared/images-lazyload/images-lazyload.module';
import { LeftProductComponent } from '../layout/left-product/left-product.component';
import { FilterBrandComponent } from './filter-brand/filter-brand.component';
import { combineLatest } from 'rxjs';
import { SharedDataServiceService } from '../../../services/shared-data-service.service';

@Component({
  selector: 'app-brand-categorie',
  standalone: true,
  imports: [CommonModule, FormsModule,NgbPaginationModule, RouterModule, LeftProductComponent, ImagesLazyloadModule, FilterBrandComponent ],
  templateUrl: './brand-categorie.component.html',
  styleUrl: './brand-categorie.component.css'
})
export class BrandCategorieComponent {
  settings: any = [];
  products: any[] = [];
  totalPages:number = 0;
  currentPage:number = 1;
  prices: any[] = [];
  origins: any[] = [];
  guarantees: any[] = [];
  filteredBrands:any=[];
  optionsSearch:any=[];
  priceFilter:string='';
  selectedBrand: any=[];
  brand_id: any='';
  slugBrand:string='';
  optionId:string='';

  categorie_slug:string='';
  categorie:any=[];
  isContentExpanded: boolean = false;

  isShowFilter: boolean = false;
  isShowOrderby: boolean = false;
  filterBrand: { id: number, name: string, slug: string }[] = [];
  brandId:string ='';
  priceId:string='';
  originId:string='';
  guaranteeId:string='';
  currentSort: string = 'new'; // Giá trị sắp xếp mặc định
  sortedProducts: any[] = [];
  activeFilterIndex: number | null = null;
  totalSearch:number = 0;
  dataLoaded:boolean=false;
  categories:any=[];

  
  constructor(
    private productService: ProductService,
    private brandService: BrandService,
    private cdr: ChangeDetectorRef,
    public activedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private settingsService: SettingsService,
    private sharedDataService: SharedDataServiceService
  ){}

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  ngOnInit(): void {
    this.loadFilters();
    this.activedRoute.queryParams.subscribe(queryParams => {
      this.priceFilter = (queryParams['p']) ? queryParams['p'] : '';
      this.optionId = (queryParams['o']) ? queryParams['o'] : '';
      this.guaranteeId = (queryParams['g']) ? queryParams['g'] : '';
      this.currentPage = queryParams['page'] ? +queryParams['page'] : 1;
    });
    this.activedRoute.params.subscribe((resp:any) => {
      this.categorie_slug=resp.slugCategorie;
      this.slugBrand=resp.slugBrand;

      this.productService.getCategoriesBrand(this.categorie_slug, this.slugBrand).subscribe((resp:any) => {
        this.categorie=resp.categorie;
        this.selectedBrand=resp.brand;
        this.brand_id=resp.brand.id;
        this.filterBrand.push({ id: this.brand_id, name: this.selectedBrand.name, slug: this.selectedBrand.slug });
        this.productService.getOptionSearch("", this.brand_id).subscribe((resp: any) => {
          this.optionsSearch = resp.option_lists;
          this.dataLoaded = true;
        });
        this.getProducts(this.currentPage);
      },(error) => {
        this.router.navigate(['/404'])
      })
    })
    this.sharedDataService.categories$.subscribe(categories => {
      this.categories = categories;  // Nhận dữ liệu categories từ SharedDataService
    });
  }
  showMore() {
    this.isContentExpanded = !this.isContentExpanded; // Đảo trạng thái
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.getProducts(this.currentPage);
  }
  getProducts(page=1): void {
    this.productService.getProductsCategorie(page, this.categorie.id, this.priceFilter, this.brand_id, this.currentSort, this.optionId, this.guaranteeId).subscribe((data: any) => {
      this.products = data.products;
      this.totalPages = data.total;
    });
  }
  
  loadPage(page: number): void {
    this.currentPage = page;
  
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge'
    });
    this.getProducts(page);
  }
  onImageError(event: any): void {
    event.target.src = 'assets/images/no-image.jpg'; // Đặt ảnh thay thế khi ảnh bị lỗi
  }
  loadFilters() {
    this.productService.getPriceSearchs().subscribe((resp: any) => {
      this.prices = resp.prices;
    });
  }

  onFilterChanged(filters: any) {
    this.applyFilters(filters);
  }

  applyFilters(filters: any) {
    this.priceFilter = filters.prices.map((price: any) => price.id).join(',');
    this.optionId = filters.options.map((option: any) => option.id).join(',');
    // const guaranteeIds = filters.guarantees.map((guarantee: any) => guarantee.id).join(',');

    this.router.navigate([], {
      queryParams: {
        p: this.priceFilter,
        o: this.optionId,
        // g: guaranteeIds
      },
      queryParamsHandling: 'merge'
    });console.log("A");
    this.getProducts();
  }

  clearAllFilters() {
    this.router.navigate([], { queryParams: {} });
    this.totalSearch = 0;
    this.getProducts();
  }
  getActiveSortText() {
    switch (this.currentSort) {
      case 'popularity': return 'Bán chạy nhất';
      case 'new': return 'Mới nhất';
      case 'price': return 'Giá thấp đến cao';
      case 'price-desc': return 'Giá cao đến thấp';
      default: return '';
    }
  }
  sortProducts(orderBy: string) {
    this.currentSort=orderBy;
    this.getProducts();
    this.isShowOrderby=false;
  }
  showOrderby(){
    this.isShowOrderby = !this.isShowOrderby;
  }
}
