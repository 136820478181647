import { ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { BrandService } from '../service/brand.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '../../product/service/product.service';
import { ImagesLazyloadModule } from '../../../shared/images-lazyload/images-lazyload.module';
import { FilterBrandComponent } from '../../product/brand-categorie/filter-brand/filter-brand.component';
import { SharedDataServiceService } from '../../../services/shared-data-service.service';

@Component({
  selector: 'app-detail-brand',
  standalone: true,
  imports: [FormsModule, NgbPaginationModule, CommonModule, RouterModule, ImagesLazyloadModule,FilterBrandComponent],
  templateUrl: './detail-brand.component.html',
  styleUrl: './detail-brand.component.css'
})
export class DetailBrandComponent {
  //@Input() categories: any[] | undefined;
  products: any[] = [];
  settings:any=[];
  totalPages:number = 0;
  currentPage:number = 1;
  prices: any[] = [];
  origins: any[] = [];
  guarantees: any[] = [];
  filteredBrands:any=[];
  optionsSearch:any=[];
  priceFilter:string='';
  optionId:string='';
  dataLoaded:boolean=false;

  brand_slug:string='';
  brand:any=[];
  brand_id:string='';
  categories:any=[];
  productSale:any=[];
  isContentExpanded: boolean = false;

  currentSort: string = 'new'; // Giá trị sắp xếp mặc định
  sortedProducts: any[] = [];
  activeFilterIndex: number | null = null;
  totalSearch:number = 0;
  priceId:string='';
  originId:string='';
  guaranteeId:string='';
  isShowOrderby: boolean = false;

  
  constructor(
    private brandService: BrandService,
    private productService: ProductService,
    private cdr: ChangeDetectorRef,
    public activedRoute: ActivatedRoute,
    private router: Router,
    private sharedDataService: SharedDataServiceService
  ){}

  ngAfterViewInit() {
    // if (isPlatformBrowser(this.platformId)) {
    // this.initSlider();
    // }
    this.cdr.detectChanges();
  }
  ngOnInit(): void {
    this.loadFilters();
    this.activedRoute.queryParams.subscribe(queryParams => {
      this.priceFilter = (queryParams['p']) ? queryParams['p'] : '';
      this.optionId = (queryParams['o']) ? queryParams['o'] : '';
      this.guaranteeId = (queryParams['g']) ? queryParams['g'] : '';
      this.currentPage = queryParams['page'] ? +queryParams['page'] : 1;

      //if (this.priceFilter || this.brandId || this.optionId) this.getProducts(this.currentPage);
    });
    this.activedRoute.params.subscribe((resp:any) => {
      this.brand_slug = resp.slug;
      this.brandService.getBrandsDetail(this.brand_slug).subscribe((response:any) => {
        this.brand=response.brand;
        this.brand_id=response.brand.id;
        this.productService.getOptionSearch("", this.brand_id).subscribe((resp: any) => {
          this.optionsSearch = resp.option_lists;
          this.dataLoaded = true;
        });
        this.getProducts(this.currentPage);
      })
      
      
    })
    this.getCategories();
    this.sharedDataService.categories$.subscribe(categories => {
      this.categories = categories;  // Nhận dữ liệu categories từ SharedDataService
    });
  }
  getCategories(){
    this.productService.getCategories().subscribe((resp:any) =>{
      //this.categories=resp.categories;
      this.productSale=resp.productSale;
    })
  }
  showMore() {
    this.isContentExpanded = !this.isContentExpanded; // Đảo trạng thái
  }
  onPageChange(page: number): void {
    this.currentPage = page;
    this.getProducts(this.currentPage);
  }

  getProducts(page=1): void {
    this.productService.getProductsCategorie(page, '', this.priceFilter, this.brand_id, this.currentSort, this.optionId, this.guaranteeId).subscribe((data: any) => {
      this.products = data.products;
      this.totalPages = data.total;
    });
  }
  loadPage(page: number): void {
    this.currentPage = page;
  
    // Cập nhật URL với tham số page
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge' // Giữ lại các query params khác nếu có
    });
  
    // Thực hiện tải dữ liệu hoặc các hành động khác cho trang mới
    this.getProducts(this.currentPage);
  }
  loadFilters() {
    this.productService.getPriceSearchs().subscribe((resp: any) => {
      this.prices = resp.prices;
    });
  }

  onFilterChanged(filters: any) {
    this.applyFilters(filters);
  }

  applyFilters(filters: any) {
    this.priceFilter = filters.prices.map((price: any) => price.id).join(',');
    this.optionId = filters.options.map((option: any) => option.id).join(',');
    // const guaranteeIds = filters.guarantees.map((guarantee: any) => guarantee.id).join(',');

    this.router.navigate([], {
      queryParams: {
        p: this.priceFilter,
        o: this.optionId,
        // g: guaranteeIds
      },
      queryParamsHandling: 'merge'
    });
    this.getProducts();
  }

  clearAllFilters() {
    this.router.navigate([], { queryParams: {} });
    this.totalSearch = 0;
    this.getProducts();
  }
  getActiveSortText() {
    switch (this.currentSort) {
      case 'popularity': return 'Bán chạy nhất';
      case 'new': return 'Mới nhất';
      case 'price': return 'Giá thấp đến cao';
      case 'price-desc': return 'Giá cao đến thấp';
      default: return '';
    }
  }
  sortProducts(orderBy: string) {
    this.currentSort=orderBy;
    this.getProducts();
    this.isShowOrderby=false;
  }
  showOrderby(){
    this.isShowOrderby = !this.isShowOrderby;
  }
}
