import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICEIO } from '../config/config';

interface CartItem {
  productId: number;
  name: string;
  sku: string;
  photo: string;
  price: number;
  quantity: number;
  variation?: Variation[]; // optional: e.g., "Size: M, Color: Red"
  variationId: number;
}

interface Variation {
  id: number;
  name_attribute: string;
  attribute_id: number;
  name_property: string;
  id_property: number;
  price_add: number;
  //value_add: string;
}
@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartItems: CartItem[] = [];
  private cart = new BehaviorSubject<CartItem[]>(this.cartItems);
  private cartItemCount = new BehaviorSubject<number>(0);

  cart$ = this.cart.asObservable();
  cartItemCount$ = this.cartItemCount.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.loadCartFromLocalStorage();
    }
  }

  addToCart(item: CartItem) {
    const index = this.cartItems.findIndex(
      i => i.productId === item.productId && i.variationId === item.variationId
    );
  
    if (index > -1) {
      // Cập nhật số lượng
      this.cartItems[index].quantity += item.quantity;
    } else {
      // Thêm mới sản phẩm
      this.cartItems.push(item);
    }
  
    this.cart.next(this.cartItems);
      this.cartItemCount.next(this.cartItemCount.value + item.quantity);
      if (isPlatformBrowser(this.platformId)) {
        this.updateCartInLocalStorage();
      }
    }

    getCartItems() {
      return this.cartItems;
    }

    removeFromCart(productId: number, variationId: number) {
      // Lọc ra những item không thỏa mãn điều kiện
      this.cartItems = this.cartItems.filter(item => 
          item.productId !== productId || item.variationId !== variationId
      );

      // Tính lại số lượng sản phẩm trong giỏ hàng
      const totalItemsCount = this.cartItems.reduce((count, item) => count + item.quantity, 0);

      // Cập nhật giá trị cartItemCount
      this.cartItemCount.next(totalItemsCount);

      // Kiểm tra và cập nhật localStorage nếu ứng dụng đang chạy trên trình duyệt
      if (isPlatformBrowser(this.platformId)) {
          this.updateCartInLocalStorage();
      }
      
      // Phát giá trị mới của giỏ hàng
      this.cart.next(this.cartItems);
  }
  updateQuantity(updatedItem: CartItem) {
    const index = this.cartItems.findIndex(
      i => i.productId === updatedItem.productId && i.variationId === updatedItem.variationId
    );
  
    if (index > -1) {
      this.cartItems[index].quantity = updatedItem.quantity;
      //this.cartItems[index].subtotal = this.cartItems[index].quantity * this.cartItems[index].price;
      //this.cartItems[index].total = this.cartItems[index].quantity * this.cartItems[index].subtotal;
      this.cart.next(this.cartItems);
      this.updateCartInLocalStorage();

      // Tính lại số lượng sản phẩm trong giỏ hàng
      const totalItemsCount = this.cartItems.reduce((count, item) => count + item.quantity, 0);

      // Cập nhật giá trị cartItemCount
      this.cartItemCount.next(totalItemsCount);
    }
  }

  clearCart() {
    this.cartItems = [];
    if (isPlatformBrowser(this.platformId)) {
      this.updateCartInLocalStorage();
    }
    this.cart.next(this.cartItems);
    // Cập nhật giá trị cartItemCount
    this.cartItemCount.next(0);
  }

  private loadCartFromLocalStorage() {
    if (isPlatformBrowser(this.platformId)) {
      const storedCart = localStorage.getItem('cart');
      if (storedCart) {
        this.cartItems = JSON.parse(storedCart);
        this.cart.next(this.cartItems);
        this.cartItemCount.next(this.cartItems.reduce((count, item) => count + item.quantity, 0));
      }
    }
  }

  private updateCartInLocalStorage() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('cart', JSON.stringify(this.cartItems));
    }
  }

  createOrder(orderData: any, token:any) {
    let headers = {};
    if (token) {
      headers = { 'Authorization': `Bearer ${token}` };
    }
    let URL=URL_SERVICEIO+"/frontEnd/orders";
    return this.http.post(URL, orderData,{headers});
  }
  getOrder(orderID: number){
    let URL=URL_SERVICEIO+"/frontEnd/orders/"+orderID;
    return this.http.get(URL);
  }
  getOrderCode(orderCode: string){
    let URL=URL_SERVICEIO+"/frontEnd/orders/code/"+orderCode;
    return this.http.get(URL);
  }
}
