<div class="filter-total mb-3">
    <div class="filter-item__title jsTitle" (click)="toggleFilter(1)">
      <div class="arrow-filter" [ngClass]="{ active: activeFilterIndex === 1 }"></div>
      <i class="fas fa-filter"></i> Lọc 
      <strong class="number count-total" [ngClass]="{ active: totalSearch > 0 }">{{ totalSearch }}</strong>
    </div>
   
     <div class="filter-show show-total" id="wrapper" [ngClass]="{ active: activeFilterIndex === 1 }">
        <div class="list-filter-active" [ngClass]="{'active': (filterBrand.length > 0 || filterOrigin.length > 0 || filterPrice.length > 0 || filterOption.length > 0)}">
           <b class="me-3">Đã chọn: </b>
           <div class="manu d-inline">
              <ng-container *ngIf="filterBrand.length > 0">
                 <span *ngFor="let item of filterBrand">
                    {{ item.name }} <i class="fal fa-times ms-2" (click)="removeFilter(item,'brand')"></i>
                 </span>
              </ng-container>
              
           </div>
            <div class="price d-inline">
              <ng-container *ngIf="filterPrice.length > 0">
                 <span *ngFor="let item of filterPrice">
                    {{ item.name }} <i class="fal fa-times ms-2" (click)="removeFilter(item,'price')"></i>
                 </span>
              </ng-container>
            </div>
            <div class="price d-inline">
              <ng-container *ngIf="filterOption.length > 0">
                 <span *ngFor="let item of filterOption">
                    {{ item.name }} <i class="fal fa-times ms-2" (click)="removeFilter(item,'options')"></i>
                 </span>
              </ng-container>
            </div>
           <!-- <div class="price d-inline">
              <ng-container *ngIf="filterOrigin.length > 0">
                 <span *ngFor="let item of filterOrigin">
                    {{ item.name }} <i class="fal fa-times ms-2" (click)="removeFilter(item,'origin')"></i>
                 </span>
              </ng-container>
           </div>
           <div class="price d-inline">
              <ng-container *ngIf="filterGuarantee.length > 0">
                 <span *ngFor="let item of filterGuarantee">
                    {{ item.name }} <i class="fal fa-times ms-2" (click)="removeFilter(item,'guarantee')"></i>
                 </span>
              </ng-container>
           </div> -->
           <a routerLink="/{{categorie.slug}}-c{{ categorie.id }}" class="clr-filter" (click)="clearFilter()" >Xóa tất cả</a>
        </div>
        <div class="show-total-main">
           <a href="javascript:;" class="close-popup-total" (click)="closeFilter()">
              <i class="far fa-times-circle me-1"></i> Đóng
           </a>
           <div class="show-total-item clearfix warpper-manu-inside arranged">
              <p class="show-total-txt">Hãng</p>
              <div class="filter-list filter-list--hang manu d-flex flex-wrap align-items-center">
                 <ng-container *ngFor="let brand of brands">
                    <a class="c-btnbox filter-manu" data-id="{{brand.id}}" data-name="{{brand.name}}" (click)="addFilter(brand,'brand')" [ngClass]="{'active': isActive(brand.id,'brand')}">
                       <img *ngIf="brand.photo; else usePhoto" appLazyLoad="{{ brand.photo }}" src="assets/images/400x400.png" alt="{{brand.name}}" class="img-fluid" loading="lazy" (error)="onImageError($event)" width="68" height="30">
                       <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                       <ng-template #usePhoto>
                             <img src="assets/images/no-image.jpg" alt="{{brand.name}}" class="img-fluid" width="68" height="30">
                       </ng-template>
                    </a>
                 </ng-container>
              </div>
           </div>
           <div class="filter-border"></div>
           <div class="show-total-item count-item arranged">
              <div class="row">
                 <div class="col-md-4 col-12 mb-3">
                    <p class="show-total-txt">Giá</p>
                    <div class="filter-list price d-flex flex-wrap align-items-center">
                       <ng-container  *ngFor="let item of prices">
                          <a class="c-btnbox " data-id="{{item.id}}" (click)="addFilter(item,'price')" [ngClass]="{'active': isActive(item.id,'price')}" >{{item.name}} </a>
                       </ng-container>
                    </div>
                 </div>
                  <!-- <div class="col-md-4 col-12 mb-3">
                    <p class="show-total-txt">Xuất xứ</p>
                    <div class="filter-list price d-flex flex-wrap align-items-center">
                       <ng-container  *ngFor="let item of origins">
                          <a class="c-btnbox " data-id="{{item.id}}" (click)="addFilter(item,'origin')" [ngClass]="{'active': isActive(item.id,'origin')}">{{item.name}} </a>
                       </ng-container>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <p class="show-total-txt">Bảo hành</p>
                     <div class="filter-list price d-flex flex-wrap align-items-center">
                       <ng-container  *ngFor="let item of guarantees">
                          <a class="c-btnbox " data-id="{{item.id}}" (click)="addFilter(item,'guarantee')" [ngClass]="{'active': isActive(item.id,'guarantee')}">{{item.name}} </a>
                       </ng-container>
                     </div>
                  </div> -->
                  <div class="col-md-4 col-12" *ngFor="let item of optionsSearch">
                     <p class="show-total-txt">{{ item.name }}</p>
                     <div class="filter-list price d-flex flex-wrap align-items-center">
                        <ng-container  *ngFor="let option of item.options">
                           <a class="c-btnbox " data-id="{{option.id}}" (click)="addFilter(option,'options')" [ngClass]="{'active': isActive(option.id,'options')}">{{option.name}} </a>
                        </ng-container>
                      </div>
                  </div>
              </div>
              
           </div>
           
           
           <div class="filter-border"></div>
           <div class="filter-button filter-button--total" style="display: block;">
              <a href="javascript:;" class="btn-filter-close" (click)="clearFilter()">Bỏ chọn</a>
              <a href="javascript:;" class="btn-filter-readmore" (click)="filterSearch()">Xem kết quả </a>
           </div>
        </div>
     </div>
  </div>
   <ng-container *ngIf="brandSelected.length==0 && priceSelected.length==0 && optionSelected.length==0; else useFilter">
      <div class="filter-item d-inline position-relative ms-2">
         <div class="filter-item__title" (click)="toggleFilter(2)">
         <div class="arrow-filter" [ngClass]="{ active: activeFilterIndex === 2 }"></div>
         <span>Hãng sản xuất</span>
         </div>
         <div class="filter-option" [ngClass]="{ active: activeFilterIndex === 2 }">
         <p class="show-total-txt mt-4">Hãng sản xuất</p>
         <div class="filter-childs filter-list--hang d-flex flex-wrap align-items-center">
            <ng-container *ngFor="let brand of brands">
               <a class="c-btnbox filter-manu" data-id="{{brand.id}}" data-name="{{brand.name}}" 
                  (click)="addFilter(brand, 'brand')" [ngClass]="{'active': isActive(brand.id,'brand')}">
               <img *ngIf="brand.photo" [src]="brand.photo" class="img-fluid" width="68" height="30" />
               <ng-template #usePhoto><img src="assets/images/no-image.jpg" class="img-fluid" width="68" height="30" /></ng-template>
               </a>
            </ng-container>
         </div>
         <div class="button-filter-area" *ngIf="filterBrand.length > 0">
               <div class="filter-button">
                  <a href="/{{categorie.slug}}-c{{ categorie.id }}" class="btn-filter-close" (click)="clearFilter()">Bỏ chọn</a>
                  <a href="javascript:;" class="btn-filter-readmore" (click)="filterSearch()">Xem kết quả </a>
               </div>
            </div>
         </div>
      </div>
      <div class="filter-item d-inline position-relative ms-2">
         <div class="filter-item__title" (click)="toggleFilter(3)">
            <div class="arrow-filter" [ngClass]="{ active: activeFilterIndex === 3 }"></div>
            <span>Giá</span>
         </div>
         <div class="filter-option" [ngClass]="{ active: activeFilterIndex === 3 }">
            <p class="show-total-txt mt-3">Giá</p>
            <div class="filter-list price d-flex flex-wrap align-items-center">
               <ng-container  *ngFor="let item of prices">
                  <a class="c-btnbox " data-id="{{item.id}}" (click)="addFilter(item,'price')" [ngClass]="{'active': isActive(item.id,'price')}">{{item.name}} </a>
               </ng-container>
            </div>
            <div class="button-filter-area" *ngIf="filterPrice.length > 0">
               <div class="filter-button">
                  <a href="/{{categorie.slug}}-c{{ categorie.id }}" class="btn-filter-close" (click)="clearFilter()">Bỏ chọn</a>
                  <a href="javascript:;" class="btn-filter-readmore" (click)="filterSearch()">Xem kết quả </a>
               </div>
            </div>
         </div>
      </div>
      <div class="filter-item d-inline position-relative ms-2" *ngFor="let item of optionsSearch; let i = index">
         <div class="filter-item__title" (click)="toggleFilter(i+4)">
            <div class="arrow-filter" [ngClass]="{ active: activeFilterIndex === i+4 }"></div>
            <span>{{ item.name }}</span>
         </div>
         <div class="filter-option" [ngClass]="{ active: activeFilterIndex === i+4 }">
            <p class="show-total-txt mt-3">{{ item.name }}</p>
            <div class="filter-list price d-flex flex-wrap align-items-center">
                  <ng-container  *ngFor="let option of item.options">
                     <a class="c-btnbox " data-id="{{option.id}}" (click)="addFilter(option,'options')" [ngClass]="{'active': isActive(option.id,'options')}">{{option.name}} </a>
                  </ng-container>
            </div>
            <div class="button-filter-area" *ngIf="filterOption.length > 0">
               <div class="filter-button">
                  <a href="/{{categorie.slug}}-c{{ categorie.id }}" class="btn-filter-close" (click)="clearFilter()">Bỏ chọn</a>
                  <a href="javascript:;" class="btn-filter-readmore" (click)="filterSearch()">Xem kết quả </a>
               </div>
            </div>
         </div>
      </div>
   </ng-container>
   <ng-template #useFilter>
      <div class="lst-quicklink d-flex flex-wrap align-items-center " *ngIf="totalSearch==0">
         <a class="c-btnbox filter-manu" *ngFor="let brand of brandSelected" (click)="addFilterSearch(brand, 'brand')">
            {{ brand.name }}
            <!-- <img *ngIf="brand.photo" [src]="brand.photo" class="img-fluid" width="68" height="30" />
            <ng-template #usePhoto><img src="assets/images/no-image.jpg" class="img-fluid" width="68" height="30" /></ng-template> -->
         </a>
         <a class="c-btnbox filter-manu" *ngFor="let item of priceSelected" (click)="addFilterSearch(item, 'price')">
            {{ item.name }}
         </a>
         <a class="c-btnbox filter-manu" *ngFor="let item of optionSelected" (click)="addFilterSearch(item, 'options')">
            {{ item.name }}
         </a>
      </div>
      <div class="filter-item d-inline position-relative ms-2 list-filter-active" *ngIf="totalSearch>0">
         <ng-container *ngIf="filterBrand.length > 0">
            <span *ngFor="let item of filterBrand">
               {{ item.name }} <i class="fal fa-times ms-2" (click)="removeFilter(item,'brand')"></i>
            </span>
         </ng-container>
         <ng-container *ngIf="filterPrice.length > 0">
            <span *ngFor="let item of filterPrice">
               {{ item.name }} <i class="fal fa-times ms-2" (click)="removeFilter(item,'price')"></i>
            </span>
         </ng-container>
         <ng-container *ngIf="filterOption.length > 0">
            <span *ngFor="let item of filterOption">
               {{ item.name }} <i class="fal fa-times ms-2" (click)="removeFilter(item,'options')"></i>
            </span>
         </ng-container>
      </div>
   </ng-template>
   
<div class="layer-search" *ngIf="activeFilterIndex==1" (click)="activeFilterIndex = null"></div>