import { Component, Input } from '@angular/core';
import { ProductService } from '../../service/product.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ImagesLazyloadModule } from '../../../../shared/images-lazyload/images-lazyload.module';
import { SharedDataServiceService } from '../../../../services/shared-data-service.service';

@Component({
  selector: 'app-left-product',
  standalone: true,
  imports: [RouterModule,CommonModule,ImagesLazyloadModule],
  templateUrl: './left-product.component.html',
  styleUrl: './left-product.component.css'
})
export class LeftProductComponent {
  //categories:any=[];
  productSale:any=[];
  isLoading = true;
  skeletonArray = Array(8);
  @Input() categories: any[] | undefined;
  constructor(
    private productService: ProductService,
    private sharedDataService: SharedDataServiceService
  ){}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.getCategories();
    this.sharedDataService.categories$.subscribe(categories => {
      this.categories = categories;  // Nhận dữ liệu categories từ SharedDataService
    });
  }
  getCategories(){
    this.productService.getCategories().subscribe((resp:any) =>{
      //this.categories=resp.categories;
      this.productSale=resp.productSale;
      this.isLoading=false;
    })
  }
}
