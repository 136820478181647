<main class="bg-gray-100 py-3">
    <div class="container py-5">
        <div class="row">
            <div class="col-md-3 col-12">
                 <app-left-product></app-left-product>
            </div>
            <div class="col-md-9 col-12">
               <div class="bg-white p-3">
                  <section class="breadcrumb__area include-bg mb-3">
                     <div class="breadcrumb__content position-relative z-index-1">
                        <div class="breadcrumb__list mb-3">
                           <span><a routerLink="/">Trang chủ</a></span>
                           <span>{{categorie.name}}</span>
                        </div>
                        <h1 class="breadcrumb__title">{{categorie.name}}</h1>
                     </div>
                 </section>
                  <div class="grid_fillter mb-3">
                     <div class="d-flex flex-wrap justify-content-between">
                        <div class="col-12">
                           <ng-container *ngIf="dataLoaded">
                              <app-filter
                              [brands]="brands"
                              [optionsSearch]="optionsSearch"
                              [prices]="prices"
                              [origins]="origins"
                              [guarantees]="guarantees"
                              [totalSearch]="totalSearch"
                              [categorie]="categorie"
                              [optionSelected]="optionSelected"
                              [brandSelected]="brandSelected"
                              [priceSelected]="priceSelected"
                              (filterChanged)="onFilterChanged($event)"
                              (clearAllFilters)="clearAllFilters()"
                              ></app-filter>
                           </ng-container>
                        </div>
                        <div class="col-md-12 col-xs-12">
                           <div class="orderby">
                              <div class="head_order" (click)="showOrderby()">Sắp xếp: <span class="d-md-none">{{ getActiveSortText() }}</span></div>
                              <ul class="ordering-list" [ngClass]="{'active': isShowOrderby==true}">
                                 
                                 <li>
                                    <button (click)="sortProducts('new')" [ngClass]="{'active': currentSort === 'new'}" type="button">
                                    Mới nhất
                                    </button>
                                 </li>
                                 <li>
                                    <button (click)="sortProducts('popularity')" [ngClass]="{'active': currentSort === 'popularity'}" type="button">
                                    Bán chạy nhất
                                    </button>
                                 </li>
                                 <li>
                                    <button (click)="sortProducts('price')" [ngClass]="{'active': currentSort === 'price'}" type="button">
                                       Giá thấp đến cao
                                    </button>
                                 </li>
                                 <li>
                                    <button (click)="sortProducts('price-desc')" [ngClass]="{'active': currentSort === 'price-desc'}" type="button">
                                       Giá cao đến thấp
                                    </button>
                                 </li>
                              </ul>
                              </div>
                        </div>
                     </div>
                  </div>
                 
                  <div class="grid_container">
                     <div class="columns-md-3 columns-xs-6 mb-4 item_product" *ngFor="let product of products">
                        <div class="sale" *ngIf="product.sale">{{ product.sale }}%</div>
                        <div class="images">
                           <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                              <img *ngIf="product.image; else usePhoto" appLazyLoad="{{ product.image.thumbnail }}" src="assets/images/400x400.png" alt="{{product.name}}" class="img-fluid" loading="eager" onerror="this.onerror=null;this.src='assets/images/no-image.jpg';">
                              <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                              <ng-template #usePhoto>
                              <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid">
                              </ng-template>
                           </a>
                        </div>
                        <div class="info p-4 py-2">
                           <div class="tp-product-category"><a routerLink="{{ product.categorie_first.slug }}-c{{ product.categorie_first.id }}">{{ product.categorie_first.name }}</a></div>
                           <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                              <h3>{{ product.name }}</h3>
                           </a>
                           <div class="tp-product-rating-icon">
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           </div>
                           <div class="price">
                              <span *ngIf="product.price" class="me-3">{{ product.price | number:'1.0-0' }} đ</span>
                              <strong *ngIf="product.priceSale > 0">
                                 {{ product.priceSale | number:'1.0-0' }} đ
                              </strong>
                              <strong *ngIf="product.priceSale <= 0">
                                 Liên hệ
                              </strong>
                           </div>
                        </div>
                     </div>
                  </div>
                     <ngb-pagination *ngIf="totalPages/20>1"
                        [collectionSize]="totalPages"
                        [(page)]="currentPage"
                        [pageSize]="20"
                        [rotate]="true"
                        [boundaryLinks]="true"
                        (pageChange)="loadPage($event)"
                        [maxSize]="5"
                  ></ngb-pagination>
   
                  <div class="contentDetail mh-200 bg-white p-3 my-3" *ngIf="categorie.content" [ngStyle]="{'height': isContentExpanded ? 'auto' : '200px'}">
                     <div class="content" [innerHTML]="categorie.content"></div>
                     <div class="viewMore">
                         <a class="btn btn_readmore ripple-surface" (click)="showMore()" [class.active]="isContentExpanded"><span>{{ isContentExpanded ? 'Rút gọn' : 'Xem thêm' }}</span> <svg class="svg svg-more-down ml-3" xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6"><path fill="none" fill-rule="evenodd" stroke="#08711c" stroke-linecap="round" stroke-width="2" d="M7.5 1.5l-3 3-3-3"></path></svg></a>
                     </div>
                 </div>
               </div>
            </div>
        </div>
    </div>
</main>