import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';
import { ImagesLazyloadModule } from '../../../shared/images-lazyload/images-lazyload.module';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [CommonModule, RouterModule, ImagesLazyloadModule],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.css'
})
export class FilterComponent {
  @Input() brands: any[] = [];
  @Input() optionsSearch: any[] = [];
  @Input() prices: any[] = [];
  @Input() origins: any[] = [];
  @Input() guarantees: any[] = [];
  @Input() categorie: any = {};
  @Input() brandSelected: any = {};
  @Input() priceSelected: any = {};
  @Input() optionSelected: any = {};
  @Input() totalSearch: number = 0;
  @Output() filterChanged = new EventEmitter<any>();
  @Output() clearAllFilters = new EventEmitter<void>();
  @Output() searchTriggered = new EventEmitter<void>();

  activeFilterIndex: number | null = null;

  filterBrand: { id: number, name: string, slug: string }[] = [];
  filterPrice: { id: number, name: string, slug: string }[] = [];
  filterOrigin: { id: number, name: string, slug: string }[] = [];
  filterGuarantee: { id: number, name: string, slug: string }[] = [];
  filterOption: { id: number, name: string, slug: string }[] = [];

  constructor(
    private eRef: ElementRef,
    private route: ActivatedRoute
  ){}
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length === 0) {
        this.resetAllFilters();
      } else {
        this.initializeFiltersFromParams(params);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalSearch'] && !changes['totalSearch'].firstChange) {
      this.countFilterSearch();
    }
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   // Chỉ khởi tạo bộ lọc khi 'guarantees' có dữ liệu
  //   if (changes['guarantees'] && changes['brands'] && changes['prices'] && changes['option'] && this.brands.length && this.prices.length && this.optionsSearch.length && this.guarantees.length) {
  //     this.route.queryParams.subscribe((params) => {
  //       this.initializeFiltersFromParams(params);
  //       if (Object.keys(params).length === 0) {
  //         this.clearFilter();
  //       } else {
  //           this.initializeFiltersFromParams(params);
  //       }
  //     });
  //   }
  // }
  toggleFilter(index: number): void {
    this.activeFilterIndex = this.activeFilterIndex === index ? null : index;
  }

  addFilter(item: any, type: string): void {
    const filterArray = this.getFilterArray(type);
    const index = filterArray.findIndex(i => i.id === item.id);

    if (index === -1) {
      filterArray.push({ id: item.id, name: item.name, slug: item.slug });
    } else {
      filterArray.splice(index, 1);
    }
    this.countFilterSearch();
  }
  addFilterSearch(item: any, type: string): void {
    const filterArray = this.getFilterArray(type);
    const index = filterArray.findIndex(i => i.id === item.id);

    if (index === -1) {
      filterArray.push({ id: item.id, name: item.name, slug: item.slug });
    } else {
      filterArray.splice(index, 1);
    }
    this.countFilterSearch();
    this.emitFilterChange();
  }
  initializeFiltersFromParams(params: any): void {
    if (params.brand) {
      const brandIds = params.brand.split(',').map(Number);
      this.filterBrand = this.brands.filter((brand) => brandIds.includes(brand.id));
    }
    if (params.p) {
      const priceIds = params.p.split(',').map(Number);
      this.filterPrice = this.prices.filter((price) => priceIds.includes(price.id));
    }
    if (params.o) {
      const optionIds = params.o.split(',').map(Number);
      this.filterOrigin = this.optionsSearch.filter((option) => optionIds.includes(option.id));
    }
    
    // if (params.o) {
    //   const originIds = params.o.split(',').map(Number);
    //   this.filterOrigin = this.origins.filter((origin) => originIds.includes(origin.id));
    // }
    // if (params.g) {
    //   const guaranteeIds = params.g.split(',').map(Number);
    //   this.filterGuarantee = this.guarantees.filter((guarantee) => guaranteeIds.includes(guarantee.id));
    // }
    this.countFilterSearch();
  }

  isActive(id: number, type: string): boolean {
    const filterArray = this.getFilterArray(type);
    return filterArray.some(item => item.id === id);
  }

  clearFilter(): void {
    this.filterBrand = [];
    this.filterPrice = [];
    this.filterOrigin = [];
    this.filterGuarantee = [];
    this.filterOption=[];
    this.activeFilterIndex = null;
    this.clearAllFilters.emit();
  }
  resetAllFilters(): void {
    this.filterBrand = [];
    this.filterPrice = [];
    this.filterOrigin = [];
    this.filterGuarantee = [];
    this.filterOption = [];
    this.totalSearch = 0;
  }
  countFilterSearch(){
    const counts=this.filterBrand.length + this.filterPrice.length + this.filterOrigin.length + this.filterGuarantee.length + this.filterOption.length;
    this.totalSearch=counts;
  }

  removeFilter(item: any, type: string): void {
    const filterArray = this.getFilterArray(type);
    const index = filterArray.findIndex(i => i.id === item.id);

    if (index !== -1) {
      filterArray.splice(index, 1);
      this.emitFilterChange();
    }
  }
  filterSearch(): void {
    //this.searchTriggered.emit(); // Trigger search on click
    this.emitFilterChange();
  }
  closeFilter(): void {
    this.activeFilterIndex = null;
  }

  private emitFilterChange(): void {
    const filters = {
      brands: this.filterBrand,
      prices: this.filterPrice,
      options: this.filterOption
    };
    this.activeFilterIndex = null;
    this.filterChanged.emit(filters);
  }

  private getFilterArray(type: string): any[] {
    switch (type) {
      case 'brand': return this.filterBrand;
      case 'price': return this.filterPrice;
      case 'origin': return this.filterOrigin;
      case 'guaarntee': return this.filterGuarantee;
      case 'options': return this.filterOption;
      default: return [];
    }
  }
  onImageError(event: any): void {
    event.target.src = 'assets/images/no-image.jpg'; // Đặt ảnh thay thế khi ảnh bị lỗi
  }
  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (this.activeFilterIndex !== null && !this.eRef.nativeElement.contains(event.target)) {
      this.activeFilterIndex = null;
    }
  }
}
