import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataServiceService {

  constructor() { }
  private categoriesSource = new BehaviorSubject<any[]>([]);  // Khởi tạo với một mảng rỗng
  categories$ = this.categoriesSource.asObservable();  // Observable để các component có thể subscribe

  updateCategories(categories: any[]): void {
    this.categoriesSource.next(categories);  // Cập nhật giá trị cho categories
  }
}
