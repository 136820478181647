import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavComponent } from '../nav/nav.component';
import { Router, RouterModule } from '@angular/router';
import { SettingsService } from '../../services/settings.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AuthService } from '../../pages/auth/service/auth.service';
import { CartService } from '../../services/cart.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NavComponent, RouterModule, CommonModule, FormsModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
  //changeDetection: ChangeDetectionStrategy.OnPush // Sử dụng OnPush
})
export class HeaderComponent implements OnInit  {
  openNav = false;

  settings: any = null;
  user: any;
  token: string | null = null;
  cartItemCount: number = 0;
  searchKeyword: string = '';

  constructor(
    private settingsService: SettingsService,
    private authService: AuthService,
    private cartService: CartService,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngOnInit(): void {
    this.authService.token$.subscribe((token) => {
      this.token = token;
    });
    
    this.authService.user$.subscribe((user) => {
      this.user = user;
    });
    //this.user = this.authService.user;
    //this.token = this.authService.token;
    if (isPlatformBrowser(this.platformId)) {
      this.settingsService.settings$.subscribe(
        (data) => {
          this.settings = data;
        },
        (error) => {
          console.error('Error fetching settings:', error);
        }
      );
      this.cartService.cartItemCount$.subscribe(count => {
        this.cartItemCount = count;
      });
    }
  }
  toggleNav() {
    this.openNav = !this.openNav;
  }
  searchProducts() {
    if (this.searchKeyword.trim()) {
        // Chuyển hướng đến URL dạng `tim-kiem/tukhoa`
        const encodedKeyword = encodeURIComponent(this.searchKeyword.trim());
        this.router.navigate(['/tim-kiem', encodedKeyword]);
    }
}
}